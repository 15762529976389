@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-primary {
  @apply bg-custom-green-1 text-custom-blue-1 font-normal w-auto py-2 px-5 flex items-center justify-center font-semibold;
}

.button-secondary {
  @apply bg-gray-300 text-custom-blue-1 font-normal w-auto py-2 px-5 flex items-center justify-center font-semibold;
}

.active-styles {
  @apply bg-custom-green-1 text-white;
}

.inactive-styles {
  @apply text-gray-600 hover:bg-custom-green-1 hover:text-white;
}
